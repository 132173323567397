@import "../styles.module.scss";

$desktopWidth: $mobileWidth;

.newsItem {
	display: flex;
	min-width: fit-content;
	box-shadow: 0 0 2rem rgba(black, 0.2);

	@media only screen and (min-width: $desktopWidth) {
		margin-right: 2rem;
	}

	@media only screen and (max-width: $desktopWidth) {
		margin: 0.5rem;
		flex-direction: column;
		margin-bottom: 3rem;
		width: calc(100vw - 1rem);

		&.clicked,
		&:hover {
			transform: unset;

			.description {
				width: 100vw;

				h3,
				p {
					animation: unset;
					max-height: 20rem;
				}
			}
		}
	}

	.image {
		img {
			max-height: 30rem;
			width: 100%;
			height: 100%;
			object-fit: cover;

			@media only screen and (min-width: $desktopWidth) {
				min-width: fit-content;
			}
		}

		@media only screen and (min-width: $desktopWidth) {
			border-radius: 0.25rem 0 0 0.25rem;
		}

		@media only screen and (max-width: $desktopWidth) {
			border-radius: 0.25rem 0.25rem 0 0;
		}
	}

	.content {
		background-color: $colorBgLight;
		position: relative;

		@media only screen and (min-width: $desktopWidth) {
			padding-bottom: 2rem;
		}

		@media only screen and (max-width: $desktopWidth) {
			width: 100%;
			padding: 2rem 0;
		}

		.type {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			padding: 0.5rem 1rem;
			background-color: black;
			color: white;
			border-radius: 0 0 0.25rem 0.25rem;

		}

		h3 {
			color: $colorLightWarm;

			@media only screen and (min-width: $desktopWidth) {
				padding: 3rem 2rem 1rem 2rem;
			}

			@media only screen and (max-width: $desktopWidth) {
				width: calc(100% - 4rem);
				padding: 1rem 2rem 0rem 2rem;
			}
		}

		p {
			max-width: 20rem;
			color: $colorLightWarm;
			max-height: 8rem;
			mask-image: linear-gradient(black, black 60%, transparent 90%, transparent);

			@media only screen and (min-width: $desktopWidth) {
				padding: 0 2rem 3rem 2rem;
			}

			@media only screen and (max-width: $desktopWidth) {
				width: calc(100% - 4rem);
				padding: 0 2rem 5rem 2rem;
			}
		}

		a {
			text-align: center;
			font-weight: bold;
			color: $colorLightWarm;
			cursor: pointer;

			@media only screen and (min-width: $desktopWidth) {
				padding: 0 2rem 3rem 2rem;
			}

			@media only screen and (max-width: $desktopWidth) {
				padding: 2rem;
			}

			&:hover {
				filter: brightness(0.5);
			}
		}
	}
}