@import "../../styles.module.scss";

.page {
    @extend .page;

    @media (max-width: $mobileWidth) {
        padding: $paddingMobile;
        align-items: flex-start;

        >* {
            width: calc(100% - #{$paddingMobile} * 2);
        }
    }

    @media (min-width: $mobileWidth) {
        flex-direction: row-reverse;
        width: calc(100% - 8rem);
        align-items: flex-start;
        justify-content: start;
        padding: 2rem 4rem;

        >:last-child {
            flex: 1;
        }
    }

    .uploadDiv {
        @media (max-width: $mobileWidth) {
            width: calc(100% - 2 * #{$paddingMobile});
            margin-bottom: 2rem;
        }

        @media (min-width: $mobileWidth) {
            width: 15rem;
        }

        .upload {
            :global(.ant-upload) {
                width: 100%;
                aspect-ratio: 1.6;
                padding: 0;
            }

            :global(.ant-upload-drag) {
                border-radius: 0.5rem;
            }

            .icon {
                animation: notify 2s infinite;
                margin-top: 1rem;

                svg {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

    .reports {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (max-width: $mobileWidth) {
            padding: $paddingMobile;
        }

        @media (min-width: $mobileWidth) {}

        .report {
            margin-bottom: 1rem;

            @media (max-width: $mobileWidth) {}

            @media (min-width: $mobileWidth) {
                max-width: 20rem;
            }
        }

        .loading {
            margin-top: 1rem;

            svg {
                height: 1.5rem;
                width: 1.5rem;
            }
        }

        .empty {
            font-size: 0.8rem;
            color: $colorLightWarm;
        }
    }
}