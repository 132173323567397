@import "../../styles.module.scss";

.modal {
    >:first-child {
        h1 {
            display: inline;
            margin-bottom: 0rem;
            margin-right: 0.5rem;
        }

        label {
            font-size: 2rem;
        }

        h2 {
            display: inline;
            color: $colorLightWarm;
            font-size: 1.25rem;
            margin-left: 0.5rem;
        }
    }

    h3 {
        color: $colorLightWarm;
        margin-top: 2rem;
    }

    table {
        border-color: collapse;
    }

    tr {

        >* {
            border-right: 1px solid white;
        }

        >:last-child {
            border-right: none;
        }

        &:nth-child(odd) {
            td {
                background-color: $colorBgLight;
            }


            .blank {
                background-color: unset;
            }
        }

        &:last-child {
            td {
                border-bottom: none !important;
            }
        }
    }

    td {
        border-bottom: 1px solid white;
        padding: 0.5rem;
    }

    p {
        font-size: 1rem;
        margin-bottom: 2rem;
    }

    ol {
        li {
            margin-bottom: 1rem;
        }
    }
}