$colorBg: #191917;
$colorBgDark: #10100a;
$colorBgLight: #21211E;
$colorDefault: white;
$colorBorder: #4b494380;
$colorLight: #bbb;
$colorDanger: #ed2945;
$colorPrimary: #287aff;
$colorSecondary: #3a5272;
$colorOK: #ADE792;
$colorWarning: #F94A29;
$colorLightWarm: #fae6d1;
$shadow: 0 0 2rem rgba(#191917, 0.2);

$mobileWidth: 768px;
$paddingMobile: 1rem;

.page {
    flex: 1;
    width: 100vw;
    overflow: hidden;
    background-color: $colorBgDark;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addButton {
    font-size: 1rem;
    height: 3rem;

    @media (max-width: $mobileWidth) {
        width: calc(100% - 2 * #{$paddingMobile});
        margin: $paddingMobile;
    }

}

.spacer {
    flex: 1;
}

.hoverable {

    &,
    >* {

        &:hover {
            filter: brightness(0.9);
        }
    }
}

.clickable {
    @extend .hoverable;

    &,
    >* {
        cursor: pointer;
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.awesomeScrollBar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, .5);
    }
}

@keyframes notify {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

body {
    background-color: $colorBg;
    height: 100vh;
    color: $colorDefault;
}

:global(.ant-select-item-option-content),
:global(.ant-select-selection-placeholder) {
    font-size: 1rem;
}


@media only screen and (max-width: $mobileWidth) {
    html {
        font-size: max(5vmin, 14px);
    }
}

@media only screen and (min-width: $mobileWidth) {
    html {
        font-size: 14px;
    }
}