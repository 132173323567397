@import "../../styles.module.scss";

.report {
    @extend .clickable;
    border-radius: 0.5rem;
    background-color: $colorBgLight;
    padding: 0.5rem 1rem;

    @media (max-width: $mobileWidth) {}

    @media (min-width: $mobileWidth) {}

}