@import "../../styles.module.scss";

.page {
	@extend .page;
	align-items: flex-start;

	@media (max-width: $mobileWidth) {
		padding: $paddingMobile;

		>* {
			width: calc(100% - #{$paddingMobile} * 2);
		}
	}

	@media (min-width: $mobileWidth) {
		padding: 2rem 4rem;
	}

	.events {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-top: 1rem;
		flex-wrap: wrap;

		@media (min-width: $mobileWidth) {
			>* {
				margin-right: 2rem;
				margin-bottom: 2rem;
			}
		}
	}
}

.editor { 

	.hint {
		color: $colorLightWarm;
	}

	.title {
		margin-bottom: 0.5rem;
		margin-top: 1rem;
	}

	.input {
		img {
			width: 100%;
			object-fit: cover;
			display: block;
		}

		.icon {
			svg {
				height: 2rem;
				width: 2rem;
			}
		}
	}

	.textarea {
		min-height: 12rem;
	}

	>:last-child {
		margin-bottom: unset;
	}
}