@import "../../styles.module.scss";

.page {
	@extend .page;

	@media (max-width: $mobileWidth) {
		padding: $paddingMobile;
		align-items: flex-start;

		>* {
			width: calc(100% - #{$paddingMobile} * 2);
		}
	}

	@media (min-width: $mobileWidth) {
		align-items: flex-start;
		padding: 2rem 4rem;
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;

		h3 {
			font-size: 1rem;
			margin-right: 2rem;
		}
	}

	.loading {
		margin-top: 1rem;
		height: 1.5rem;
		width: 1.5rem;

		svg {
			height: 1.5rem;
			width: 1.5rem;
		}
	}

	.empty {
		font-size: 0.8rem;
		color: $colorLightWarm;
	}

	.shares {
		display: flex;

		@media (max-width: $mobileWidth) {
			flex-direction: column;

			>* {
				margin-bottom: 2rem;
			}
		}

		@media (min-width: $mobileWidth) {
			>* {
				margin-right: 2rem;
			}
		}
	}
}