@import "../styles.module.scss";

.App {
    height: 100vh;
    overflow-x: hidden;
    color: $colorDefault;

    @media screen and (min-width: $mobileWidth) {

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: black;
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: black;
        }
    }

    >:first-child {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
}