@import "../styles.module.scss";


.postItem {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 2rem rgba(black, 0.2);

  @media (max-width: $mobileWidth) {
    margin-bottom: 2rem;
    width: 100vw;
  }

  @media (min-width: $mobileWidth) {
    margin: 2rem 1rem 1rem 0rem;
    max-width: 25rem;
  }

  .image {
    img {
      max-height: 20rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    @media (max-width: $mobileWidth) {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    @media (min-width: $mobileWidth) {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }

  .contentDiv {
    display: flex;
    flex-direction: column;
    background-color: black;
    position: relative;
    color: $colorDefault;
    padding: 1rem;

    .content {
      font-weight: bold;

      @media (max-width: $mobileWidth) {
        font-size: 0.8rem;
      }

      @media (min-width: $mobileWidth) {
        font-size: 1rem;
      }
    }

    .date {
      font-weight: 100;

      @media (max-width: $mobileWidth) {
        margin-top: 0.6rem;
        font-size: 0.6rem;
      }

      @media (min-width: $mobileWidth) {
        margin-top: 1rem;
        font-size: 0.8rem;
      }
    }
  }
}