@import "../../styles.module.scss";

.page {
	@extend .page;
	align-items: flex-start;

	@media (min-width: $mobileWidth) {
		padding: 2rem 4rem;
	}

	.preview {
		background-color: $colorBgLight;
		padding: 1rem;
		border-radius: 0.5rem;
		margin-bottom: 1rem;

		a {
			color: $colorPrimary;
		}

		@media (max-width: $mobileWidth) {
			margin: $paddingMobile;
		}
	}

	.items {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		margin-top: 1rem;

		@media (max-width: $mobileWidth) {
			flex-direction: column;
		}

		@media (min-width: $mobileWidth) {
			flex-wrap: wrap;
			width: calc(100% - 8rem);
		}

	}
}

.editor {

	.hint {
		color: $colorLightWarm;
	}

	>* {
		margin-bottom: 1rem;
	}

	.content {
		margin-bottom: 0.5rem;
		margin-top: 1rem;
	}

	.textarea {
		min-height: 12rem;
	}

	>:last-child {
		margin-bottom: unset;
	}
}