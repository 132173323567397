@import "../../styles.module.scss";

.page {
	@extend .page;

	@media (max-width: $mobileWidth) {
		padding: $paddingMobile;
		align-items: flex-start;

		>* {
			width: calc(100% - #{$paddingMobile} * 2);
		}
	}

	@media (min-width: $mobileWidth) {
		flex-direction: row;
		align-items: flex-start;
		padding: 5rem 4rem;
	}

	.title {
		margin-bottom: 0.5rem;
		font-size: 1rem;
	}

	.uploadDiv {
		@media (max-width: $mobileWidth) {
			width: 50%;
			margin-bottom: 2rem;
		}

		@media (min-width: $mobileWidth) {
			width: 15rem;
		}

		.upload {
			:global(.ant-upload) {
				width: 100%;
				aspect-ratio: 0.6;
				padding: 0;
			}

			:global(.ant-upload-drag) {
				border-radius: 0.5rem;
			}

			img {
				border-radius: 0.5rem;
				width: 100%;
				height: 100%;
				margin-top: 1px;
				object-fit: cover;
				mask: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
			}

			.icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				animation: notify 2s infinite;

				svg {
					width: 3rem;
					height: 3rem;
				}
			}
		}
	}

	.inputs {

		@media (max-width: $mobileWidth) {
			width: calc(100% - #{$paddingMobile} * 2);
			margin: 2rem 0;
		}

		@media (min-width: $mobileWidth) {
			width: 30rem;
			margin: 0 2rem;
		}

		.input {
			margin-bottom: 2rem;
			font-size: 1rem;
		}

		.textarea {
			min-height: 12rem;
		}

		>:last-child {
			margin-bottom: unset;
		}
	}
}