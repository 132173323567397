@import "../../styles.module.scss";

.page {
    @extend .page;

    @media (max-width: $mobileWidth) {
        padding: $paddingMobile;
        align-items: flex-start;

        >* {
            width: calc(100% - #{$paddingMobile} * 2);
        }
    }

    @media (min-width: $mobileWidth) {
        align-items: flex-start;
        padding: 2rem 4rem;
    }

    h3 {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    .addLink {
        @media (max-width: $mobileWidth) {
            width: calc(100% - #{$paddingMobile} * 2);
        }

        @media (min-width: $mobileWidth) {
            width: min(30rem, 80%);
        }
    }

    .loading {
        margin-top: 1rem;

        svg {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    .empty {
        font-size: 0.8rem;
        color: $colorLightWarm;
    }
}