@import "../styles.module.scss";


.image {

  img {
    width: 100%;
    object-fit: cover;
    display: block;
  }

  .icon {
    min-height: 10rem;

    svg {
      height: 2rem;
      width: 2rem;
    }
  }
}