@import "../../styles.module.scss";

.page {
    @extend .page;
    justify-content: center;
    $paddingMobile: 1rem;

    @media (max-width: $mobileWidth) {
        padding: 0 $paddingMobile;
        width: calc(100vw - #{$paddingMobile} * 2);
    }

    @media (min-width: $mobileWidth) {}

    h1 {
        margin-top: 0;
    }

    .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem;
        color: $colorPrimary;

        >:first-child {
            margin-bottom: 1rem;
        }
    }

    .errorHint {
        color: $colorWarning;
    }

    .btnSignIn {
        @extend .clickable;
        outline: none;
        border-radius: 0.5rem;
        width: 15rem;
        padding: 0.5rem;
        font-size: 1rem;
        display: flex;
        align-items: center;
        font-weight: 600;

        svg {
            width: 2rem;
            height: 2rem;
            margin-right: 1rem;
        }
    }
}