@import "../styles.module.scss";

$barHeightForMobile: 4rem;

.actionBar {
    position: sticky;
    top: 0;
    z-index: 2;

    .topBar {
        display: flex;
        align-items: center;
        background-color: $colorBg;

        >:first-child {
            margin-left: 1rem;
        }

        >:last-child {
            margin-right: 1rem;
        }

        .teams {
            width: 8rem;
            background-color: $colorBgDark;

            :global(.ant-select-selection-item) {
                font-size: 1rem;
            }
        }

        @media screen and (max-width: $mobileWidth) {
            height: $barHeightForMobile;

            h1 {
                padding: 0 1rem;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        @media screen and (min-width: $mobileWidth) {
            h1 {
                padding: 1rem 2rem;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .btnMenu {
                display: none;
            }
        }
    }

    .menuBackdrop {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;

        @media screen and (max-width: $mobileWidth) {
            display: block;
        }
    }

    .menu {
        width: 100vw; 

        @media screen and (min-width: $mobileWidth) {
            padding: 0rem 2rem;
            background-color: $colorBg;
        }

        @media screen and (max-width: $mobileWidth) {
            position: fixed;
            top: $barHeightForMobile;
            left: 0;
            height: 100vh;
            width: unset;
            max-width: min(70vw, 20rem);
        } 
    }
}